<template>
  <div>
    <div class="div_center" style="padding-left: 100px;padding-top: 90px">
      <div style="width: 1200px;margin: 0 auto">
        <div class="sbxt">
          申报系统--
        </div>
        <div style="font-size: 40px;color: white;font-weight: bold">
          官方指定的科技项目申报系统
        </div>
        <div style="font-size: 60px;font-weight: bold;color: rgb(238, 157, 37);">
          帮助企业获取政府百万政策资金扶持
        </div>
        <div style="font-size: 38px;color: white;font-weight: bold">
          科技项目，一手掌握
        </div>
        <div class="ljsb" style="background-color: white;color:rgb(238, 157, 37) ">
          立即申报
        </div>
      </div>

    </div>
    <div style="display:flex;align-items:center;margin-top: 55px;height: 240px; background-color: rgb(250, 250, 250);"
         class="center">
      <img :src="fangda" alt="">
      <div style="height: 100%" class="cpjs">
        <span>产品介绍</span>
        <span style="color: #FDCE63">//////</span>
        <span>
            企业科技项目申报系统是中高联合当地主管部门，整合区域科技政策项目，打通政企申报业务流程，助力企业及时了解科技政策，精准申报科技项目，充分获取政策资源，助推企业创新发展！
          </span>
      </div>
    </div>
    <div class="center">
      <div style="height: 220px;line-height: 220px;text-align: center;font-size: 28px;font-weight: bold">
        功能特色
      </div>
      <div style="width: 1200px;padding-left: 50px">
        <Row :gutter="16">
          <Col v-for="(item,index) in gnts" :key="index" span="12">
            <div
              class="per_ts"
              style="height: 210px;width: 510px;border: 1px solid black;margin-bottom: 70px;padding-left: 40px;padding-top: 40px">
              <span>{{item.title}}</span>
              <span style="color: #FDCE63">//////</span>
              <span style="padding-right: 10px">{{item.content}}</span>
            </div>
          </Col>

        </Row>
      </div>
    </div>
    <div class='div_center_bottom'>
      <div class="bottom_center">
        <span>产品介绍</span>
        <span style="color: #FDCE63">//////</span>
        <span >
            企业科技项目申报系统是中高联合当地主管部门，整合区域科技政策项目，打通政企申报业务流程，助力企业及时了解科技政策，精准申报科技项目，充分获取政策资源，助推企业创新发展！
          </span>
      </div>
    </div>
  </div>
</template>

<script>
  import fangda from '@/assets/image/index/fangda.png'

  export default {
    name: "PolicyIndex",
    data() {
      return {
        'fangda': fangda,
        gnts: [
          {
            title: '实时获取科技政策',
            content: '企业可以实时获取最全、最新的区域、全国性的惠企政策资讯，资金补贴、税收优惠、资质品牌等政策解读，一手在握！'
          },
          {
            title: '精准提交项目申报',
            content: '企业可以实时获取最全、最新的区域、全国性的惠企政策资讯，资金补贴、税收优惠、资质品牌等政策解读，一手在握！'
          },
          {
            title: '实时更新申报进度',
            content: '项目申报完成后，企业可通过平台及时跟进申报进度，及时沟通解决申报过程中的问题，确保项目申报的顺利进行。'
          },
          {
            title: '及时公开中标信息',
            content: '平台内申报企业的中标信息将根据项目进度及时统一发布，促进政企信息公开，帮助企业了解知情。'
          }
        ]
      }
    }
  }
</script>

<style scoped lang="scss">

  .per_ts span ,  .bottom_center span{
    display: block;
    margin-bottom: 20px;
  }

  .per_ts {
    & span:first-child {
      font-size: 20px;
      color: black;
      font-weight: bold;

    }
    & span:last-child {
      font-size: 16px;
      color: black;

    }
  }
  .bottom_center {
    & span:first-child {
      font-size: 28px;
      color: black;
      font-weight: bold;

    }
    & span:last-child {
      font-size: 14px;
      color: black;
      width: 760px;
      line-height: 30px;

    }
  }
  .cpjs {
    margin-left: 45px;
    padding: 45px 0;
    & span {
      display: block;
      margin-bottom: 25px;
    }
  }

  .cpjs {
    & span:first-child {
      font-size: 28px;
      font-weight: bold;
    }
    & span:last-child {
      font-size: 14px;
      width: 670px;
    }
  }

  .center {
    width: 1200px;
    margin: 0 auto;
  }

  .div_center {
    height: 510px;
    width: 100%;

    justify-content: center;
    margin: 0 auto;
    background-image: url("~@/assets/image/index/policy.png");
    background-position: center;
  }

  .div_center_bottom {
    display:flex;
    align-items: center;
    height: 670px;
    width: 100%;
    margin: 0 auto;
    background-image: url("~@/assets/image/index/bottom.png");
    background-position: center;
  }
  .bottom_center {
    margin: 0 auto;
    height: 469px;
    width: 1200px;
    padding-top: 100px;
    padding-left: 190px;
    background-image: url("~@/assets/image/index/bottom_center.png");
    background-position: center;
  }
  .sbxt {
    background-image: linear-gradient(180deg, rgb(240, 173, 27) 0%, rgb(236, 119, 59) 100%);
    width: 175px;
    height: 49px;
    color: white;
    font-size: 28px;
    font-weight: bold;
    border-radius: 10px;
    padding: 3px 15px;
  }

  .ljsb {
    width: 191px;
    height: 49px;
    color: white;
    font-size: 28px;
    font-weight: bold;
    border-radius: 30px;
    padding: 3px 38px;
  }
</style>
